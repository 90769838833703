<template>
  <div class="page-footer">
    <footer
      class="relative w-full xl:max-w-8xl mx-auto rounded-tl-md rounded-tr-md p-2 bg-gray-200 text-center dark:bg-gray-800 mt-3 z-40"
    >
      <span class="text-xs text-gray-500 text-center dark:text-gray-400"
        >© {{ currentYear }}
        <a href="#" class="hover:underline"
          >Information Security Media Group, Corp.</a
        >
      </span>
    </footer>
  </div>
</template>
<script setup lang="ts">
const currentYear = ref<number>(new Date().getFullYear());
</script>
